
@import '../src/index.scss';
@import '../src/player.scss';

.gallery-container {
    // height: 100%;
    background: $color1o1; //whitesmoke;
    // padding-top: 3%;
    transition: all 2s;
    -webkit-font-smoothing: antialised;
    // transition-delay: 0.2s;
    // fit to content
    display: grid;
      // padding-bottom: 4em;
      // display : flex;
      align-items: flex-start;
      z-index: 1000;
  }
  
  .gallery-container-nopadding {
    padding-top: 0% !important;
    transition: 0s;
  }
  
  .darkmode {
    // background: $color1o1 !important;
    // background: linear-gradient(45deg,  transparent 0%, transparent 57%, #000000 100%),linear-gradient(-45deg, #1b0f0d 0%,  rgb(37, 15, 11) 0.3%, rgb(15, 7, 17) 112%,  transparent 11% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% );
  }
  
  .gallery-grid {
    display: grid;
    //pointer-events: none;
    z-index: 120;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .gallery-grid--show {
    pointer-events: none;
  }

  .gallery-grid--hide {
    pointer-events: none;
  }
  
  .gallery {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    // padding: 4% 2% !important; //cool
    // min-height: 100%;

    background: transparent;

    // transition: all 1s;
  }

  .gallery--centered {
    margin: auto;
  }

  .gallery--left {
    margin: 0;
  }
  
  .gallery-narrow {
    transition: all 0s;
    width: calc(100% - #{$fullwidth-offset} + #{$category-element-margin}*2);
  }
  
  .gallery-fullwidth {
    transition: all 0s;
    width: 100%;
  }



  @mixin change-category-flex-percentage($category-flex-percentage) {
  .category-wrapper {
    z-index: 10;
    flex: 1 0 $category-flex-percentage;
    align-items: flex-start;
    // min-width: 10%;
    margin: $category-element-margin; 
    transform: translateY(0%);
    // transform: translateY(0%);
    //// width: 20vw;
    //// height: 20vw;
      // &.overlap {
      // transform: translateY(-70%); //-30
      // transition: ease-in-out 1s; //1.5s;
      // }
      // &.overlap-more {
      // transform: translateY(-150%) !important;
      // transition: ease-in-out 1s; //1.5s;
      // }
      &.nooverlap {
      transform: translateY(0%);
     transition: ease-in-out 1s; //1.5s;
      }
  }
}

@media only screen and (min-width: 768px) { //desktop
  @include change-category-flex-percentage($category-flex-percentage: 22%); //$board-width => 0.3
}

@media only screen and (max-width: 768px) { //mobile
  @include change-category-flex-percentage($category-flex-percentage: 50%);
}

  .gallery-grid-show {
    opacity: 1;
    // transition: opacity 3s;
  }

  .gallery-grid-hide {
    opacity: 0;
    // transition: opacity 3s;
  }

  .category-gallery {
    align-items: center;
  }

  .category-wrapper:not(:nth-child(1n+6)), .element-wrapper:not(:nth-child(1n+4)) {
    margin-top: 2%;
  }
  
  .animation {
    opacity: 1;
    animation-name: animationX;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 5s;
  }
    
    @keyframes animationX {
      0% {
        background-color: rgb(255, 221, 67);
      }
      50% {
        background-color: rgb(255, 207, 49);
      }
      100% {
        background-color: rgb(255, 221, 67);
      }
    }
  
    // & > br 
    // {
    //    width: 100%;
    //    content: '';
    // }
  //   .gallery-row  {display:none;}
  //   .gallery-row:nth-child(4) {
  //   display:block;
  //   width:100%;
  //   height:0;
  //  }
  .category-container {
    height: 100%; //83%;
    width: 100%; //83%;
    background-color: white;
    // margin: 0px 10px;
    // height: 200px;
    // overflow: hidden;
    /* position: relative; */
    position: relative;
    border-radius: $category-element-border-radius;
    margin-top: 10vh;
    margin-bottom: 10vh;

    }
  
  .category-container-span {

    position: absolute;
    color: $color1o1; 
    font-size: 1.2em; // 1.4em;
    pointer-events: none;
    // transform: translate(15%, 12%);
    transition: all 0.15s;
    z-index: 5;
    // @extend .horizontal-center-by-transform;
    //color: white; 
    // font: bold 24px/45px Helvetica, Sans-Serif; 
    // letter-spacing: -1px;  
    // background: rgb(0, 0, 0); /* fallback color */
    // background: rgba(0, 48, 77, 0.6); // rgba(0, 0, 0, 0.7);
    padding: 10px; 
  }
  
  .category-container:hover > span {
    // filter: blur(0.2px);
    transition: all 1s;
    transform: scale(1.05);
    //font-size: 1.5em !important; //1.4em;
    // background: rgba(0, 48, 77, 0.7);
    // transform: translate(0%, -30%);
  }
  
  .category-container:hover > .category-background {
    transform: scale(1.15);
    // filter: contrast(120%);
    border-radius: 0;
  }
  
  .category-image {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: $category-element-border-radius;
    transform: scale(0.8);
  }
  
  .category-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s;
    border-radius: $category-element-border-radius;
    // 
  }

  .break {
    flex-basis: 100%;
    height: auto;
  }
  
  // .gallery-img:hover > div {
  //   // filter: contrast(120%);
  //   // transition: all ease-in-out 0.2s;
  //   // transform: scale(1.2)
  // }
  
  // .hide1, .hide2 {
  //   transition: 5s;
  //   opacity: 0.5;
  //   // display: none;
  // }
  
  // .category-gallery-show {
  //   transition: opacity 3s ease-in-out;
  //   opacity: 1;
  //   pointer-events: all;
  // }
  
  // .category-gallery-hide {
  //   transition: opacity 0s;
  //   opacity: 0;
  //   pointer-events: none;
  //   // transform: translateY(100%);
  // }
  
  .categories-gallery-show {
    // transition: opacity 1s;
    // opacity: 1;
    pointer-events: all;
    @extend .categories-gallery-show-animation;
  }

  .categories-gallery-show-animation {
    animation-name: categories-gallery-show-animation;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
}

@keyframes categories-gallery-show-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

  
  .categories-gallery-hide {
    pointer-events: none;
    transition: opacity 0.3s;
    translate: transformY(-100%);
    opacity: 0 !important;
  }
  
  // .show1, .show2 {
  
  // }
  
  
  
  
  // .grow {
  //   transition: .5s;
  //   filter: hue-rotate(50deg);
  //   flex-grow: 1.5 !important;
  
  //   max-width: 40%;
  //   transform: translateY(0%)
  // }
  
  // .grow > * > img {
  //   background-color: rgb(255, 216, 41);
  //   @extend .element-animation;
  //   z-index: 10;
  // }
  
  .element-animation {
      animation-name: element-animation;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
  }
  
  @keyframes element-animation {
      0% {
      // transform:translateY(0%)
      z-index: 10000;
      }
      100% {
      transform:translateY(100%);
      z-index: 10000;
      }
  }
  
  
  
  .element-wrapper {
  z-index: 10;
  flex: 1 0 25%;
  // min-width: 10%;
  width: 20vw;
  margin-bottom: 0%;
  margin: 1%;
  // transition: all 0.5s;
  // position: relative;
  top: 0;
  // transition: ease-in 0.2s; //1.5s;
  // box-shadow: 0px 0px 10px 5px rgba(6, 10, 14, 0.5);
  }

  .translate {

    // transform: translateY(-50vh);
    // top: -50vh;
    // position: relative;
    // top: 50%;

  } 

  .program-gallery {
    // transition: all 1s;
    transform-origin: bottom;
    z-index: 10;
  }

  .program-gallery--show {
    opacity: 1;
    transition: opacity ease-in-out 0.1s;;
    pointer-events: all;
  }

  .program-gallery--hide {
    opacity: 0;
    transition: opacity ease-in-out 0.1s;
    pointer-events: none;
  } 

  .program-gallery--low {
    transition: all 0.5s;
    transform: translateY(60vh);
  }

  .program-gallery--slideup {
    transition: all 1s;
    transform: translateY(-0vh);
  }

  .program-gallery--align-top {
    align-items: top;
  }

  .program-gallery--align-center {
    align-items: center;
  }


  
  .translate-more {
    width: 80%;
    transition: width 0s;
    // top: 15% !important;
    left: 0%;
  }
  
  .element-wrapper:hover {
    // transform: scale(1.2);
    // trex: 0 2 30% !important;
    // flex-wrap: 1.8;
  }
  
  .element-wrapper-hover {
    transition: all 0.3s;
    transform: scale(1.1);
    // flex: 0 2 30% !important;
  }

  .element-wrapper-focus {
    transform: scale(1.05);
    // animation: program-pulsating 1 ease-in-out 3s;
  }
    
  // @keyframes program-pulsating {
  //   0% {
  //     transform: scale(1);
  //   }
  //   50% {
  //     // transform: scale(1.05);
  //   }
  //   100% {
  //     transform: scale(1.05);
  //   }
  // }
  
  .element-image {
    position: relative;
    display: block;
    width: 100%;
    transition: ease-in-out 0.2s;
    z-index:30;
    // filter: saturate(120%);
  }
  
  .element-wrapper-chosen-not {
    transition: ease-in-out 0.3s;
    display: none;
    // width: 0;
    opacity: 0;
  }
  
  .preview-img-fade, .element-container-tagline-fade {
    z-index: 3;
    cursor: pointer;
    // filter: sepia(50%);
  }
  
  .preview-img-fademore {
    // z-index: 3;
    opacity: 0.2 !important;
    filter: blur(3px);
  }
  
  .preview-img-fadeout {
    z-index: 3;
    opacity: 0 !important;
    // pointer-events: none;
  }
  
  .preview-img-nopointerevents {
    pointer-events: none;
  }

  .preview-img-hovered {
    transform: scale(1.05);
    transition: transform 1s;
  }

  .preview-img-origin-left {
    transform-origin: left bottom;
  }

  .preview-img-origin-right {
    transform-origin: right bottom;
  }
  
  .fb_iframe_widget span {
    display: inline !important;
  }
  
  .element-wrapper-video-chosen {
    // flex: 0 0 200%;
    // top: 0;
    // transition: all 10s;
    // width: 40vw !important;
    // transform: scale(8) !important;
    // @extend .fade-out-in;
    transition: 0s !important;
    border-radius: 0 !important;
    flex: 0 0 100%; //420%;
    margin: 0px !important;
    // transition: all 5s;
  }
  
  .element-wrapper-video-chosen-not {
    flex: 1 1 0%;
    height: 0;
    transition: all 0s;
    opacity: 0;
    width:0; 
  }
  
  .play-mode-hide {
    transition: opacity 0.2s, padding-bottom 0s;
    opacity: 0 !important;
    // padding-bottom: 0% !important;
  }
  
  .fade-out-in {
    opacity: 1;
    animation-name: fade-out-in-animation;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }
    
  @keyframes fade-out-in-animation {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
    
  
  // .element-image:hover {
    
  // }
      
  .element-container {
    // width: 95%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    // text-align: right;
    }
  
  .element-container-noborder {
    border-radius: 0px !important;
  }
  
  .element-container-title-span {
    mix-blend-mode: color-dodge;
    position: absolute;
    font-size: calc(1.6vw);
    // white-space: nowrap;
    //word-spacing:9999px;
    // filter: blur(0.2px);
    width: 50%;
    font-weight: 900;
    pointer-events: none;
    top: 50%;
    transform: translateY(-45%);
    transition: all 0.15s;
    z-index: 100;
    line-height: 120%;
    left: 10%;
    color: $color-gradient6;
    // text-shadow: 2px 2px 5px black;
  }
  
  .element-container-tagline {
    // color: rgb(255, 174, 0);
    // mix-blend-mode: exclusion;
    // opacity: 0.6;
    // bottom: 0;
    // transform: rotate(180deg);
    position: absolute;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 40%);//linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,241,226,1) 50%);
    // opacity: 0.5;
    height: 4em;
    z-index: 50;
    cursor: pointer;
    // pointer-events: none;
  }


  
  .element-container-tagline-nopointer {
    pointer-events: none !important;
  }
  
  .element-container-tagline-span {
    // bottom: 0;
    mix-blend-mode: difference;
    //color: $color-text;
    
    //opacity: 0.8;
    padding-top: 0.45em;
    position: absolute; 
    font-size: calc(1vw);
    text-transform: uppercase;
    white-space: nowrap;
    // filter: blur(0.2px);
    font-weight: 900;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.15s;
    z-index: 100;
    color: $color1o1;
  }

  .element-container-title-span-hide, .element-container-tagline-span-hide {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .fa.fa-info {
    padding-left: 0.5em;
    color: $color-gradient6;
    // color: red;
  }
  .fa.fa-info--focus, .fa.fa-info:hover {
      // font-size: 1.1em;
      // transition: scale(2);
  }
  
  .element-container-information {
    position: absolute;
    z-index: 40;
    text-align: center;
    line-height: 1.4;
    opacity: 0;
    transition: all 0.2s;
    height: calc(100% - 4em);
    margin-top: 4em;
    margin-left: 15%;
    margin-right: 15%;
    overflow: scroll;
    padding-top: 3em;
    word-wrap: break-word;
    // text-align : justify;
    //@extend .center-by-transform
  }

  .element-container-information::-webkit-scrollbar {
    display: none;
}
  
  .element-container-information-show {
    opacity: 1;
    transition: all 0.8s;
    pointer-events: all;
    cursor: pointer;
  }

  .element-container-information-hide {
    pointer-events: none;
    opacity: 0;
  }
  
  .element-container-information-span {

    height: 100%;
    width: 100%;
    color: $color3;
    font-size: calc(0.9vw);
    font-weight: 900;
    padding-bottom: 8em;
  }

//   .element-container-information-span:before {
//     content:"";
//     left: 2%;
//     position: relative;
//     transform: translateY(-3em);
//     width: 30%;
//     height: 1em;
//     position: fixed;
//     //background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
// }

  .element-container-information-fade-top-container {
    transform: translateY(-0.4em);
    position: absolute;
    height: 2em;
    margin-top: calc(4em);
    pointer-events: none;
    z-index: 100000000000;
    width: 100%;
  }

  .element-container-information-fade-top {
    pointer-events: none;
    width: 100%;
    //background: $main-color-to-transparent-from-left;
    height: 100%;
    top: 0;
  }
  
  //.element-container:hover > span { //.element-container:hover > .element-container-tagline
  // .element-container:hover > .element-container-title-span {
  //   filter: blur(0.2px);
  //   opacity: 0;
  //   transition: opacity 0.3s;
  //   // font-size: 4em;
  //   // transform: translate(0%, -30%);
  // }
  
  .element-container:hover > .element-background {
    // transform: scale(2.01);
  }
  
  .element-left {
    width: 50%;
    height: 100%;
    position: absolute;
  }
  
  .element-right {
    transform: translateX(100%);
    width: 50%;
    height: 100%;
    position: absolute;
  }
  
  .element-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;


    // -webkit-animation-name: shimmer;
    // -moz-animation-name: shimmer;
    // -webkit-animation-name: shimmer;
    // animation-name: shimmer;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
  }


  
  @keyframes shimmer {
    0% {
      transform: scale(1);
      filter: contrast(100%);
    }
  
    50% {
      transform: scale(1.2);
      filter: contrast(115%) brightness(95%);
    }
    100% {
      transform: scale(1);
      filter: contrast(100%);
    }
  }

  .element-background-hover {
    filter: saturate(80%);
    transition: all 2s;
  }

  .element-background-nothover {
    filter: contrast(100%) brightness(100%);
    transition: all 0.3s;
  }
  
  .element-container-video-information {
    width: 100%;
    // height: 5vw;
    background-color: $color1o1;
    position: relative;
    z-index: 10000;
    
    // display: inline-flex;
    align-items: center;
    // margin: 2%;
    & .tags {
      // padding-left: 2%;
      font-size: large;
      color: $color3;
      padding-right: 0.4em;
      // background-color: blue;
    }
    & .title {
      // display: table;
      // padding-left: 2%;
      pointer-events: none;
      // font-size: xx-large;
      color: #ffae00;
    }
    & .season-episode {
      pointer-events: none;
      // font-size: x-large;
      color: white;
    }
    & .episode-title {
      pointer-events: none;
      // font-size: x-large;
      color: white;
    }
    & .episode-description {
      pointer-events: none;
      display: table;
    }
    & span {
      margin-left: 1%;
    }
  }

  .grid-container {
    display: grid;
    grid-template-rows: auto auto;
    // background-color: #2196F3;
    // padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid-item {
    background: $color1a; 
    // background-color: rgba(255, 255, 255, 0.8);
    // border: 1px solid rgba(0, 0, 0, 0.8);
    padding-left: 10px;
    // padding-right: 20px;
  
    &.grid-item:nth-child(1) {
      font-size: 1.6em
    }
  
    &.grid-item:nth-child(2) {
      font-size: 1.4em
    }
  
    // &.grid-item:nth-child(2) {
    //   font-size: 1.4em
    // }
    
    // &.grid-item:nth-child(2),  &.grid-item:nth-child(4)  {
    //   border-left: 10px solid $color1o1;
    //   // grid-column: 1 / 3;
    // }
    // text-align: justify;
    // &.grid-item:nth-child(1),  &.grid-item:nth-child(2)  {
    //   display: inline-flex;
    //   align-items: center;
    //   padding-top: 10px;
    // }
    // &.grid-item:nth-child(3), &.grid-item:nth-child(4) {
    
    //   padding-right: 20px;
    //   font-size: 0.8em;
    // }
    // &.grid-item:nth-child(1) {
    //   padding-right: 10px;
    // }
  }

  .item-counter-view2:after, .item-counter-view:after {
    line-height: var(10px);
    width: var(10px);
    height: var(10px);
}

.fas.fa-stream {
  font-size: 0.5em;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: linear-gradient(120deg, #ffae00 42%, #fdcd3b 70%);
  color: $color1o1;
  border-radius: 4em; /* or 50% width & line-height */
  padding: 6px;
  margin-left: 10px;
  border: solid 1px black;
  cursor: pointer;
  border: none;
}

.fa.fa-align-left:hover {
  // transition: all 2s;
  background: white;
}

.video-descriptions {
  transition: all 0.5s;
  // color: $color1o2;
}

.video-descriptions-show {
  opacity: 1;
  height: 1;
}

.video-descriptions-hide {
  opacity: 0;
  height: 0
}

.element-suggestion-info {
  animation: suggestionInfoShow 0.5s ease-out;
}

@keyframes suggestionInfoShow {
  0% {
  opacity: 0;
  transform: translateX(-20%);
  }
  100% {
  opacity: 1;
  transform: translateX(0%);
  }
}

.element-suggestion-info-span {
  display: block;
  font-size: 4em;
  color: white;
}

.category-fade-in {
  opacity: 1;
  transition: all 1s;
}

.category-fade-out {
  opacity: 0;
  transition: all 0.5s;
}

.category-description {
  //padding-top: 15%;
  top: 28%;
  position: absolute;
  //@extend .center-by-transform;
  pointer-events: none;
  width: 100%;
}

.category-description-text {
  display: block;
  color: white;
  font-size: 0.9em;
  line-height: 1.7em;
}

.cvoglogo {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  //margin: auto;
  //@extend .horizontal-center-by-transform;
}

.text-size3 {
  
  position: relative;
  font-size: 1em;
  //text-align: center;
  color: white;
  display: inline-block;
}

.novologo {
  display: inline-block;
  height: 4em;
  position: relative;
  text-align: top;
  margin-left: 1em;
  filter: sepia(100%) hue-rotate(190deg);

}