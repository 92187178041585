
@import '../src/index.scss';
@import '../src/player.scss';

.billboard-wrapper {
    margin: auto;
    transition: width 1s;
    position: relative;
    width: 100%;
  }

  .billboard-wrapper--non-category {
    padding-bottom: calc(#{$video-aspect-ratio * ((100% - $billboard-fullwidth-offset) / 100%)});
  }

  .billboard-wrapper--category {
    padding-bottom: 20%;
  }

  .billboard-wrapper-notactive {
    pointer-events: none;
  }

  .billboard-wrapper-active {
    pointer-events: all;
  }
  
  .billboard-wrapper-partwidth {
    width: 100%; //calc(100% - #{$billboard-fullwidth-offset});
  }
  
  .billboard-wrapper-fullwidth {
    width: 100%;
  }
  
  .billboard-wrapper-hide {
    height: 0 !important;
  }

  .billboard-test {
    position: absolute;
    /* padding-bottom: 56.25%; */
    bottom: 0;
    /* top: 0; */
    /* height: 100%; */
    width: 100%;
  }
  
  
  .billboard-container {
    width: 100%;
    transition: 1s;
    //16:9 aspect ratio
    // height: 0;
    position: relative;
    padding-bottom: $video-aspect-ratio;
    transition: 1s;
      &.hide {
        opacity: 0;
      }
  }

  .carousel-container {
    @extend .aspect-ratio-parent;
    
    &.show {
      opacity: 1;
      transition: all 0.5s;
    }
    &.hide {
      opacity: 0;
      transition: all 0s;
      // filter: contrast(120%);
    }
  }
  
  .carousel {
    z-index: 10;
  }

  .dot:focus {
    outline: none !important;
}

.player-wrapper {
  pointer-events: none;
}

  .player-wrapper-moveup {
    transform: translateY(-28%);
  }
  
  // .player-wrapper-overlay {
  //   height: 56.5%;
  //   background-color: $color1o1;
  //   z-index: 10;
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   pointer-events: none;
  //   &.hide {
  //     opacity: 0;
  //   }
  //   &.show {
  //     opacity: 1;
  //   }
  // }
  
  .player-fadein {
    opacity: 1;
    transition: 0.5s;
  }
  
  .player-fadeout {
    opacity: 0;
  }
  
  .play-container {
    transition: all 0.5s;
    position: absolute;
    z-index: 100;
    height: 10vh;
    width: 10vh;
    // border: 2px solid black;
    // border-radius: 50%;
    @extend .center-by-transform;
  }
  
  .play {
    background: white; //$color3;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top:0;
    bottom:0;
    right:0;
    opacity: 0.8;
    border: solid 2px whitesmoke;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      opacity: 1;
      border: solid 2px white;
    }
    &:before {
    // content: "";
    // display: block;
    // width: 0;
    // height: 0;
    // border-style: solid;
    // border-width: 1.1em 0 1.1em 2.6em;
    // border-color: transparent transparent transparent red;
    // position: absolute;
    // top:0;
    // left:0;
    // right:-5px;
    // bottom:0;
    // margin: auto;
    }
  }

.fa.fa-play {
  color: red;
  top: 50%;
  left: 50%;
  transform: scaleX(1.2) translate(-40%, -50%);
  font-size: 3em;
  position: absolute;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

  // .play-play {
  //   opacity: 0;
  //   height: 15vh;
  //   width: 15vh;
  // }

  // .react-player {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }