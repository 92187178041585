
@import '../src/index.scss';

.header-container {
    z-index: 100000;
    position: fixed; //or sticky
    top: 0;
    width: 100%;
    height: $header-height;
    // opacity: 1;
    transition: all 1s;
    & img {
      position: absolute;
      transition: all 1s;
      @extend .center-by-transform;
    }

  }

  .header-container-show {
    opacity: 1;
    transition: all 0.2s;
  }

  .header-container-hide {
    // opacity: 0;
    background-color: transparent !important; 
    transition: all 0.2s;
    //pointer-events: none;
  }
  
  .top {
      // opacity: 0.05;
      background-color: transparent; //$header-background-o2; //gba(20, 20, 20, 0.1)
      // filter: blur(1px);
      & span {
        font-size: 2em;
        transition: all 1s;
      }
      & img {
        height: 100%;
        transition: all 1s;
      }
    }
  
    .nottop {
      // opacity: 1;
      background-color: $header-background-o1;
      & span {
        font-size: 2em;
        // transition: all 1s;
      }
      & img {
        height: 80%;
        // transition: all 1s;
      }
    }

.header-logo {
  z-index: 10000000;
  cursor: pointer;
}

.header-logo-hide {
  opacity: 0;
}

.header-logo-show {
  opacity: 1;
}

.controls-container {
  //margin-right: 2vw;
  right: 0;
  position: absolute;
  display: block;

  height: 100%;
  width: 100%;
  //max-width: 10vh;
  color: tomato;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
}

.control {
  align-self: center;
  justify-self: left;
}

.volume {
  grid-column: 3 / span 1;
}

.pip {
  grid-column: 2 / span 1;
}

.pip-show {
  opacity: 1;
}

.pip-hide {
  opacity: 0;
}

.pip-activated {
  opacity: 1;
}

.pip-deactivated {
  opacity: 0.5;
}


.fa.volume {
position: absolute;
@extend .vertical-center-by-transform;
}

.volume-show {
  opacity: 1;
}

.volume-hide {
  opacity: 0;
}

.fa-volume-off {
  color: red;
}

.fa-volume-up {
  color: #197a4d;
}

.text-size2 {

  margin-top: 0;

  text-decoration: none;
  & a {
    text-decoration: none;
  }
}

.text-size4 {

  position: absolute;
  top: 0.4em;
  right: 1em;
  margin-top: 0;
  color: rgb(0, 116, 217) ;
  text-decoration: none;
  & a {
    text-decoration: none;
  }
}

.fa-phone  {
  font-size: 1em;
  text-align: center;
  color: rgb(0, 116, 217) !important;
  margin-right: 1em !important;
}

h1 {
  color: #197a4d;
}

a {
  color: #197a4d;
}
  

.nottop-phone {
  opacity: 1;
  transition: 1s;
}

.top-phone {
  opacity: 0;
  transition: 0.5s;
}



