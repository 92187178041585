.intro-container {
  margin-top: 10vh;
  position: relative;
  height: auto;
  margin-bottom: 10vh;
}

.intro {
  text-align: center;
}

.intro span, .fas {
  color: honeydew;
  text-align: center;
  color: honeydew;
  text-align: center;
  margin: 0;
}

.text-size1 {
  font-size: 3em;
  color: #0074d9;
  display: inline-block;
}

.green {
  color: #197a4d !important;
}

.text-size1 {
  font-size: 2.8em;
  color: #0074d9;
}

.text-size0 {
  font-size: 3em;
}

.text-size2 {
  font-size: 1.8em;
}

.text-size3 {
  font-size: 1.3em;
}

.badge {
  height: 2em;
  width: 2em;
}

