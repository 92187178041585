$color1o1:rgba(20, 20, 20, 1);
$color1o2:rgba(20, 20, 20, 0.3);
$color2o1: rgba(35, 89, 131, 1);
$color2o2: rgba(35, 89, 131, 0.5);
$color3o1: rgb(255, 174, 0);
$color1a: linear-gradient(120deg, #12222e 42%, #0f2535 70%); //#142634; //rgb(20, 20, 20);
$color-gradient1: linear-gradient(-90deg, $color1o1 42%, $color2o1 70%);
$color-gradient2: linear-gradient(-90deg, #5a9bcc 42%, #5acc37 70%);
$color-gradient3: linear-gradient(120deg, #ffae00 42%, #fdcd3b 70%);
$color-gradient4: linear-gradient(127deg, #ffae00 42%, #fdcd3b 72%);
$color-gradient5: linear-gradient(125deg, #ffae00 35%, #fdcd3b 95%, transparent 95%); // linear-gradient(125deg, #ffae00 35%, #fdcd3b 80%,  #ffae00  80%, #ffae00 95%, transparent 95%);
$color-gradient6: linear-gradient(90deg, #ffae00 35%, #ffae00  94%, transparent 95%);
$color-gradient7: linear-gradient(2deg, rgba(255, 237, 75, 1) 20%, rgba(253, 205, 59, 1) 60%);

// $color1a: linear-gradient(120deg, #0f2535 42%, #12222e 70%);
$color2:#0f0f0f;
$color3:rgba(0, 48, 77, 0.8);



$loader-color: white;

$gradient-start: $color3;
$gradient-end :$color3;

$header-background-o1: $color1o1;
$header-background-o2: $color1o2;
$footer-backgroud-o1: $color2o1;

$header-height: 8vh;
$search-container-height: 15vh;
$footer-height: 10vh;
$video-aspect-ratio: 56.25%;
$fullwidth-offset: 10%;
$category-element-margin: 1%;
$search-width: 33%;
$color-text: rgba(0, 48, 77, 0.8);
$main-color-to-transparent-from-left: linear-gradient(90deg, $color1o1 20%, transparent 80%);


$billboard-fullwidth-offset: 10%; //$fullwidth-offset;
$category-element-border-radius: 1%;

.buttontest {
  z-index: 1000000;
  position: absolute;
  height: 10px;
  width: 10px;
}

body, html {
  // height: 100%;
  width: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
  background-color: $color1o1;
  // overflow-x: hidden;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

.app {
  // min-height: 100%;
  display: flex; 
  flex-direction: column;
 
  // grid-template-rows: 2fr 1fr 1fr;
  //  grid-template-columns:
  // overflow: hidden;
}

.aspect-ratio-parent { 
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  // width: 100%;
  // grid-row-start: 1;
  // grid-column-start: 1;
  //16:9 aspect ratio
    // position: relative;
    // height: 0;
    // padding-top: 56.25%;
}

.center-by-transform {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center-by-transform {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-center-by-transform {
  top: 50%;
  transform: translateY(-50%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbars::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .disable-scrollbars {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.pointer-events-none {
  pointer-events: none !important;
}

.nopointer > div {
  pointer-events: none;
}


.loading-wrapper {
  position: absolute;
  top: 50%;
  // left: 50%;
  transform: translateY(-50%); 
  z-index: 100;
  transition: all 0.2s;
  pointer-events: none;
}

.loading-wrapper--left {
  left: 30%;
}

.loading-wrapper--right {
  right: 30%;
}

.loading-wrapper-fadein {
  opacity: 1;
}

.loading-wrapper-fadeout {
  opacity: 0;
}

$count: 10;
$time: 2; //in seconds
$size: 100;
$color: #ffffff;

.preloader {
  /* size */
  width: $size + px;
  height: $size + px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader $time + s infinite ease-in;
}
@keyframes rotatePreloader {
  0% {
  transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
  transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}

.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  // padding-top: 56%;
  opacity: 0;
}
.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: $loader-color;
  transform: translateX(-50%);
  border-radius: 50%;
}

@for $i from 1 through $count {
  .preloader div:nth-child(#{$i}) {
    transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
    animation: rotateCircle + $i $time + s infinite linear;
    z-index: $count - $i;
  }
  @keyframes rotateCircle#{$i} {
    #{percentage(((50 / $count) * ($i - 1)) / 100)} {
      opacity: 0;
    }
    #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
      opacity: 1;
      transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
    }
    #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    100% {
      transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
      opacity: 1;
    }
  }
}

// .fade-in {
// 	opacity: 1;
// 	animation-name: fadeInOpacity;
// 	animation-iteration-count: 1;
// 	animation-timing-function: ease-in;
// 	animation-duration: 0.5s;
// }

// @keyframes fadeInOpacity {
// 	0% {
// 		opacity: 0;
// 	}
// 	100% {
// 		opacity: 1;
// 	}
// }

// .fade-out {
// 	opacity: 1;
// 	animation-name: fadeOutOpacity;
// 	animation-iteration-count: 1;
// 	animation-timing-function: ease-in;
// 	animation-duration: 0.5s;
// }

// @keyframes fadeOutOpacity {
// 	0% {
// 		opacity: 1;
// 	}
// 	100% {
// 		opacity: 0;
// 	}
// }

// .gallery:hover .element-wrapper:nth-child(n + 4){ 
//   transform: scale(0.8);
// }
// .element-wrapper:hover, .element-image:hover {
//   transform: scale(1.2);
// }

// .scaledown {
//   // transform: scale(0.8);
//   // flex: 0 2 18% !important;
//   // align-items: stretch;
// }

// .ghostelement {
//   // flex: 0 2 30% !important;
//   width: 0;
//   // height: 35vh;
//   overflow: hidden;
// }


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed, 
// figure, figcaption, footer, header, hgroup, 
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure, 
// footer, header, hgroup, menu, nav, section {
// 	display: block;
// }
// body {
// 	line-height: 1;
// }
// ol, ul {
// 	list-style: none;
// }
// blockquote, q {
// 	quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
// 	content: '';
// 	content: none;
// }
// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }

.app-grid {
  // display: grid;
  // width: 100vw;
}
