
@import '../src/index.scss';

.footer-container {
    width: 100%;
    height: $footer-height;
    // // position: absolute;
    // background: #204131;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #197a4d, #204131);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #197a4d, #204131); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
 //background: linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(88,45,253,1) 100%);
    display: block;
    text-align: center;
    padding-top: 2vh;
}

.footer-container span, .footer-container a{
    text-align: center;
    // color: $color1o1;
    padding: 1vw;
}

.footer-container * {
    color: white;
    font-size: 0.8em;
}