.buttontest {
  z-index: 1000000;
  position: absolute;
  height: 10px;
  width: 10px;
}

body, html {
  width: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
  background-color: #141414;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

.app {
  display: flex;
  flex-direction: column;
}

.aspect-ratio-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.center-by-transform, .recommendation-img-focus-inner, .recommendation-img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center-by-transform, .recommendation-name-span {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-center-by-transform, .search-suggestions-container, .fas.fa-search, .search {
  top: 50%;
  transform: translateY(-50%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbars::-webkit-scrollbar, .search-suggestions-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.disable-scrollbars, .search-suggestions-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pointer-events-none {
  pointer-events: none !important;
}

.nopointer > div {
  pointer-events: none;
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  transition: all 0.2s;
  pointer-events: none;
}

.loading-wrapper--left {
  left: 30%;
}

.loading-wrapper--right {
  right: 30%;
}

.loading-wrapper-fadein {
  opacity: 1;
}

.loading-wrapper-fadeout {
  opacity: 0;
}

.preloader {
  /* size */
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: white;
  transform: translateX(-50%);
  border-radius: 50%;
}

.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}

@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }
  7% {
    transform: rotateZ(0deg);
  }
  57% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}

@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  12% {
    transform: rotateZ(-36deg);
  }
  62% {
    transform: rotateZ(-36deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}

@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }
  17% {
    transform: rotateZ(-72deg);
  }
  67% {
    transform: rotateZ(-72deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}

@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }
  22% {
    transform: rotateZ(-108deg);
  }
  72% {
    transform: rotateZ(-108deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}

@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }
  27% {
    transform: rotateZ(-144deg);
  }
  77% {
    transform: rotateZ(-144deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}

@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }
  32% {
    transform: rotateZ(-180deg);
  }
  82% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}

@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }
  37% {
    transform: rotateZ(-216deg);
  }
  87% {
    transform: rotateZ(-216deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}

@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }
  42% {
    transform: rotateZ(-252deg);
  }
  92% {
    transform: rotateZ(-252deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}

@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }
  47% {
    transform: rotateZ(-288deg);
  }
  97% {
    transform: rotateZ(-288deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}

@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }
  52% {
    transform: rotateZ(-324deg);
  }
  102% {
    transform: rotateZ(-324deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
.search-container {
  width: 100%;
  height: 15vh;
  padding-top: 2%;
  display: flex;
}

.search-container-show {
  opacity: 1;
  transition: all 0.5s;
}

.search-container-hide {
  opacity: 0;
  transition: all 0.5s;
}

.search {
  position: relative;
  margin-left: calc(10%/2);
  width: 32%;
  height: 35%;
  z-index: 2;
}

.search-field {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.form-control {
  width: 100%;
}

.input-control {
  height: 100%;
  outline: none;
  font-size: 1.8em;
  border: none;
  background: linear-gradient(90deg, #ffae00 35%, #ffae00 94%, transparent 95%);
  transition: width 1s;
  color: rgba(0, 48, 77, 0.8);
  z-index: 1;
}

.input-control-active {
  width: calc(100% - 2em);
  padding: 0 2em !important;
}

.input-control-deactivated {
  width: 0%;
  padding: 0;
}

.fas.fa-search {
  position: absolute;
  padding-left: 0.2em;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.fa-search-active, .fas.fa-search:hover {
  font-size: 2.1em;
}

.fa-search-deactivated {
  font-size: 2em;
}

.search-suggestions-container {
  position: relative;
  z-index: 1;
  width: calc(100% - 27.2%);
  height: 35%;
  margin-right: calc(10%/2);
  position: relative;
  overflow-x: auto;
}

.search-suggestion-container {
  display: flex;
  position: relative;
  padding-left: 5em;
  padding-right: 120vw;
  height: 100%;
  overflow: hidden;
  transform: translateX(-11em);
}

.search-suggestion {
  margin: auto;
  position: relative;
  height: 100%;
  background: linear-gradient(127deg, #ffae00 42%, #fdcd3b 72%);
  margin-right: 2em;
  border-radius: 10px;
  transform-origin: center;
  transition: all 0.2s;
  cursor: pointer;
}

.search-suggestion:hover {
  transition: all 0.5s;
  transform: scale(1.05);
}

.search-suggestion--highligted {
  transition: 0.2s;
  opacity: 0;
}

.search-suggestion--default {
  transition: 0.2s;
  opacity: 1;
}

.search-suggestion--dummy {
  opacity: 0 !important;
}

.search-suggestion-span {
  color: rgba(0, 48, 77, 0.8);
  display: grid;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.5em;
  height: 100%;
  white-space: nowrap;
}

.search-suggestion-span--highligted {
  color: white;
}

.search-suggestion-span--default {
  color: rgba(0, 48, 77, 0.8);
}

.recommendations-container {
  position: absolute;
  height: 15vh;
  width: 100%;
  z-index: 1000;
  pointer-events: none;
  overflow: hidden;
}

.recommendations-wrapper {
  position: relative;
  height: 15vh;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
}

.recommendations {
  pointer-events: all;
  position: absolute;
  margin-left: calc(10%/2);
  right: 0;
  display: flex;
  overflow-x: auto;
  flex: 1;
  display: flex;
  height: 100%;
  width: 65%;
}

.recommendations-show {
  opacity: 1;
  transition: all 0.5s;
}

.recommendations-hide {
  opacity: 0;
  transition: all 0.3s;
}

.recommendations-slideout {
  transform: translateX(110%);
}

.recommendations-slidein-animation, .recommendations-slidein {
  animation-name: recommendations-slidein-animation;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
}

@keyframes recommendations-slidein-animation {
  0% {
    transform: translateX(110%);
  }
  30% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0%);
  }
}
.recommendations-fade-left-container {
  width: 65%;
  position: absolute;
  right: 0;
  z-index: 100000000;
  height: 100%;
}

.recommendations-fade-left {
  pointer-events: none;
  width: 2em;
  background: linear-gradient(90deg, #141414 20%, transparent 80%);
  height: 100%;
}

.recommendations::-webkit-scrollbar {
  display: none;
}

.recommendation {
  position: relative;
  min-height: 15vh;
  min-width: 15vh;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.recommendation-img, .recommendation-img-focus-outer {
  border-radius: 50%;
  margin: auto;
}

.recommendation-img {
  height: calc(15vh*0.8);
  width: calc(15vh*0.8);
  position: absolute;
  z-index: 2;
  margin: auto;
}

.recommendation-img:hover + .recommendation-img-focus-outer > .recommendation-img-focus-middle > .recommendation-img-focus-inner {
  height: calc(15vh*0.9);
  width: calc(15vh*0.9);
  transition: all 0.2s;
}

.recommendation-img-focus-outer {
  position: absolute;
  height: calc(15vh);
  width: calc(15vh);
  z-index: 1;
}

.recommendation-img-focus-middle {
  position: relative;
  height: calc(15vh);
  width: calc(15vh);
  overflow-y: hidden;
  margin: auto;
}

.recommendation-img-focus-inner {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(120deg, #ffae00 42%, #fdcd3b 70%);
  z-index: 1000;
  height: calc(15vh*0.8);
  width: calc(15vh*0.8);
}

.recommendation-name {
  position: relative;
}

.recommendation-name-span {
  color: white;
  top: 1em;
  position: absolute;
  z-index: 1000;
}

