.buttontest {
  z-index: 1000000;
  position: absolute;
  height: 10px;
  width: 10px;
}

body, html {
  width: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
  background-color: #141414;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

.app {
  display: flex;
  flex-direction: column;
}

.aspect-ratio-parent, .player-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.center-by-transform {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center-by-transform {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-center-by-transform {
  top: 50%;
  transform: translateY(-50%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.disable-scrollbars {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pointer-events-none {
  pointer-events: none !important;
}

.nopointer > div {
  pointer-events: none;
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  transition: all 0.2s;
  pointer-events: none;
}

.loading-wrapper--left {
  left: 30%;
}

.loading-wrapper--right {
  right: 30%;
}

.loading-wrapper-fadein {
  opacity: 1;
}

.loading-wrapper-fadeout {
  opacity: 0;
}

.preloader {
  /* size */
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: white;
  transform: translateX(-50%);
  border-radius: 50%;
}

.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}

@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }
  7% {
    transform: rotateZ(0deg);
  }
  57% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}

@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  12% {
    transform: rotateZ(-36deg);
  }
  62% {
    transform: rotateZ(-36deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}

@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }
  17% {
    transform: rotateZ(-72deg);
  }
  67% {
    transform: rotateZ(-72deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}

@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }
  22% {
    transform: rotateZ(-108deg);
  }
  72% {
    transform: rotateZ(-108deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}

@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }
  27% {
    transform: rotateZ(-144deg);
  }
  77% {
    transform: rotateZ(-144deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}

@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }
  32% {
    transform: rotateZ(-180deg);
  }
  82% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}

@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }
  37% {
    transform: rotateZ(-216deg);
  }
  87% {
    transform: rotateZ(-216deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}

@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }
  42% {
    transform: rotateZ(-252deg);
  }
  92% {
    transform: rotateZ(-252deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}

@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }
  47% {
    transform: rotateZ(-288deg);
  }
  97% {
    transform: rotateZ(-288deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}

@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }
  52% {
    transform: rotateZ(-324deg);
  }
  102% {
    transform: rotateZ(-324deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
.buttontest {
  z-index: 1000000;
  position: absolute;
  height: 10px;
  width: 10px;
}

body, html {
  width: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
  background-color: #141414;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

.app {
  display: flex;
  flex-direction: column;
}

.aspect-ratio-parent, .player-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.center-by-transform {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center-by-transform {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-center-by-transform {
  top: 50%;
  transform: translateY(-50%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.disable-scrollbars {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pointer-events-none {
  pointer-events: none !important;
}

.nopointer > div {
  pointer-events: none;
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  transition: all 0.2s;
  pointer-events: none;
}

.loading-wrapper--left {
  left: 30%;
}

.loading-wrapper--right {
  right: 30%;
}

.loading-wrapper-fadein {
  opacity: 1;
}

.loading-wrapper-fadeout {
  opacity: 0;
}

.preloader {
  /* size */
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: white;
  transform: translateX(-50%);
  border-radius: 50%;
}

.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}

@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }
  7% {
    transform: rotateZ(0deg);
  }
  57% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}

@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  12% {
    transform: rotateZ(-36deg);
  }
  62% {
    transform: rotateZ(-36deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}

@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }
  17% {
    transform: rotateZ(-72deg);
  }
  67% {
    transform: rotateZ(-72deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}

@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }
  22% {
    transform: rotateZ(-108deg);
  }
  72% {
    transform: rotateZ(-108deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}

@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }
  27% {
    transform: rotateZ(-144deg);
  }
  77% {
    transform: rotateZ(-144deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}

@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }
  32% {
    transform: rotateZ(-180deg);
  }
  82% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}

@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }
  37% {
    transform: rotateZ(-216deg);
  }
  87% {
    transform: rotateZ(-216deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}

@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }
  42% {
    transform: rotateZ(-252deg);
  }
  92% {
    transform: rotateZ(-252deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}

@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }
  47% {
    transform: rotateZ(-288deg);
  }
  97% {
    transform: rotateZ(-288deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}

@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }
  52% {
    transform: rotateZ(-324deg);
  }
  102% {
    transform: rotateZ(-324deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
.player-wrapper {
  z-index: 2;
}

.gallery-container {
  background: #141414;
  transition: all 2s;
  -webkit-font-smoothing: antialised;
  display: grid;
  align-items: flex-start;
  z-index: 1000;
}

.gallery-container-nopadding {
  padding-top: 0% !important;
  transition: 0s;
}

.gallery-grid {
  display: grid;
  z-index: 120;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

.gallery-grid--show {
  pointer-events: none;
}

.gallery-grid--hide {
  pointer-events: none;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  background: transparent;
}

.gallery--centered {
  margin: auto;
}

.gallery--left {
  margin: 0;
}

.gallery-narrow {
  transition: all 0s;
  width: calc(100% - 10% + 1%*2);
}

.gallery-fullwidth {
  transition: all 0s;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .category-wrapper {
    z-index: 10;
    flex: 1 0 22%;
    align-items: flex-start;
    margin: 1%;
    transform: translateY(0%);
  }
  .category-wrapper.nooverlap {
    transform: translateY(0%);
    transition: ease-in-out 1s;
  }
}
@media only screen and (max-width: 768px) {
  .category-wrapper {
    z-index: 10;
    flex: 1 0 50%;
    align-items: flex-start;
    margin: 1%;
    transform: translateY(0%);
  }
  .category-wrapper.nooverlap {
    transform: translateY(0%);
    transition: ease-in-out 1s;
  }
}
.gallery-grid-show {
  opacity: 1;
}

.gallery-grid-hide {
  opacity: 0;
}

.category-gallery {
  align-items: center;
}

.category-wrapper:not(:nth-child(1n+6)), .element-wrapper:not(:nth-child(1n+4)) {
  margin-top: 2%;
}

.animation {
  opacity: 1;
  animation-name: animationX;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-duration: 5s;
}

@keyframes animationX {
  0% {
    background-color: #ffdd43;
  }
  50% {
    background-color: #ffcf31;
  }
  100% {
    background-color: #ffdd43;
  }
}
.category-container {
  height: 100%;
  width: 100%;
  background-color: white;
  /* position: relative; */
  position: relative;
  border-radius: 1%;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.category-container-span {
  position: absolute;
  color: #141414;
  font-size: 1.2em;
  pointer-events: none;
  transition: all 0.15s;
  z-index: 5;
  padding: 10px;
}

.category-container:hover > span {
  transition: all 1s;
  transform: scale(1.05);
}

.category-container:hover > .category-background {
  transform: scale(1.15);
  border-radius: 0;
}

.category-image {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: 1%;
  transform: scale(0.8);
}

.category-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s;
  border-radius: 1%;
}

.break {
  flex-basis: 100%;
  height: auto;
}

.categories-gallery-show {
  pointer-events: all;
}

.categories-gallery-show-animation, .categories-gallery-show {
  animation-name: categories-gallery-show-animation;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
}

@keyframes categories-gallery-show-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.categories-gallery-hide {
  pointer-events: none;
  transition: opacity 0.3s;
  translate: transformY(-100%);
  opacity: 0 !important;
}

.element-animation {
  animation-name: element-animation;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes element-animation {
  0% {
    z-index: 10000;
  }
  100% {
    transform: translateY(100%);
    z-index: 10000;
  }
}
.element-wrapper {
  z-index: 10;
  flex: 1 0 25%;
  width: 20vw;
  margin-bottom: 0%;
  margin: 1%;
  top: 0;
}

.program-gallery {
  transform-origin: bottom;
  z-index: 10;
}

.program-gallery--show {
  opacity: 1;
  transition: opacity ease-in-out 0.1s;
  pointer-events: all;
}

.program-gallery--hide {
  opacity: 0;
  transition: opacity ease-in-out 0.1s;
  pointer-events: none;
}

.program-gallery--low {
  transition: all 0.5s;
  transform: translateY(60vh);
}

.program-gallery--slideup {
  transition: all 1s;
  transform: translateY(0vh);
}

.program-gallery--align-top {
  align-items: top;
}

.program-gallery--align-center {
  align-items: center;
}

.translate-more {
  width: 80%;
  transition: width 0s;
  left: 0%;
}

.element-wrapper-hover {
  transition: all 0.3s;
  transform: scale(1.1);
}

.element-wrapper-focus {
  transform: scale(1.05);
}

.element-image {
  position: relative;
  display: block;
  width: 100%;
  transition: ease-in-out 0.2s;
  z-index: 30;
}

.element-wrapper-chosen-not {
  transition: ease-in-out 0.3s;
  display: none;
  opacity: 0;
}

.preview-img-fade, .element-container-tagline-fade {
  z-index: 3;
  cursor: pointer;
}

.preview-img-fademore {
  opacity: 0.2 !important;
  filter: blur(3px);
}

.preview-img-fadeout {
  z-index: 3;
  opacity: 0 !important;
}

.preview-img-nopointerevents {
  pointer-events: none;
}

.preview-img-hovered {
  transform: scale(1.05);
  transition: transform 1s;
}

.preview-img-origin-left {
  transform-origin: left bottom;
}

.preview-img-origin-right {
  transform-origin: right bottom;
}

.fb_iframe_widget span {
  display: inline !important;
}

.element-wrapper-video-chosen {
  transition: 0s !important;
  border-radius: 0 !important;
  flex: 0 0 100%;
  margin: 0px !important;
}

.element-wrapper-video-chosen-not {
  flex: 1 1 0%;
  height: 0;
  transition: all 0s;
  opacity: 0;
  width: 0;
}

.play-mode-hide {
  transition: opacity 0.2s, padding-bottom 0s;
  opacity: 0 !important;
}

.fade-out-in {
  opacity: 1;
  animation-name: fade-out-in-animation;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fade-out-in-animation {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.element-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.element-container-noborder {
  border-radius: 0px !important;
}

.element-container-title-span {
  mix-blend-mode: color-dodge;
  position: absolute;
  font-size: 1.6vw;
  width: 50%;
  font-weight: 900;
  pointer-events: none;
  top: 50%;
  transform: translateY(-45%);
  transition: all 0.15s;
  z-index: 100;
  line-height: 120%;
  left: 10%;
  color: linear-gradient(90deg, #ffae00 35%, #ffae00 94%, transparent 95%);
}

.element-container-tagline {
  position: absolute;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 40%);
  height: 4em;
  z-index: 50;
  cursor: pointer;
}

.element-container-tagline-nopointer {
  pointer-events: none !important;
}

.element-container-tagline-span {
  mix-blend-mode: difference;
  padding-top: 0.45em;
  position: absolute;
  font-size: 1vw;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 900;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.15s;
  z-index: 100;
  color: #141414;
}

.element-container-title-span-hide, .element-container-tagline-span-hide {
  opacity: 0;
  transition: opacity 0.3s;
}

.fa.fa-info {
  padding-left: 0.5em;
  color: linear-gradient(90deg, #ffae00 35%, #ffae00 94%, transparent 95%);
}

.element-container-information {
  position: absolute;
  z-index: 40;
  text-align: center;
  line-height: 1.4;
  opacity: 0;
  transition: all 0.2s;
  height: calc(100% - 4em);
  margin-top: 4em;
  margin-left: 15%;
  margin-right: 15%;
  overflow: scroll;
  padding-top: 3em;
  word-wrap: break-word;
}

.element-container-information::-webkit-scrollbar {
  display: none;
}

.element-container-information-show {
  opacity: 1;
  transition: all 0.8s;
  pointer-events: all;
  cursor: pointer;
}

.element-container-information-hide {
  pointer-events: none;
  opacity: 0;
}

.element-container-information-span {
  height: 100%;
  width: 100%;
  color: rgba(0, 48, 77, 0.8);
  font-size: 0.9vw;
  font-weight: 900;
  padding-bottom: 8em;
}

.element-container-information-fade-top-container {
  transform: translateY(-0.4em);
  position: absolute;
  height: 2em;
  margin-top: 4em;
  pointer-events: none;
  z-index: 100000000000;
  width: 100%;
}

.element-container-information-fade-top {
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
}

.element-left {
  width: 50%;
  height: 100%;
  position: absolute;
}

.element-right {
  transform: translateX(100%);
  width: 50%;
  height: 100%;
  position: absolute;
}

.element-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
}

@keyframes shimmer {
  0% {
    transform: scale(1);
    filter: contrast(100%);
  }
  50% {
    transform: scale(1.2);
    filter: contrast(115%) brightness(95%);
  }
  100% {
    transform: scale(1);
    filter: contrast(100%);
  }
}
.element-background-hover {
  filter: saturate(80%);
  transition: all 2s;
}

.element-background-nothover {
  filter: contrast(100%) brightness(100%);
  transition: all 0.3s;
}

.element-container-video-information {
  width: 100%;
  background-color: #141414;
  position: relative;
  z-index: 10000;
  align-items: center;
}
.element-container-video-information .tags {
  font-size: large;
  color: rgba(0, 48, 77, 0.8);
  padding-right: 0.4em;
}
.element-container-video-information .title {
  pointer-events: none;
  color: #ffae00;
}
.element-container-video-information .season-episode {
  pointer-events: none;
  color: white;
}
.element-container-video-information .episode-title {
  pointer-events: none;
  color: white;
}
.element-container-video-information .episode-description {
  pointer-events: none;
  display: table;
}
.element-container-video-information span {
  margin-left: 1%;
}

.grid-container {
  display: grid;
  grid-template-rows: auto auto;
  padding-bottom: 10px;
}

.grid-item {
  background: linear-gradient(120deg, #12222e 42%, #0f2535 70%);
  padding-left: 10px;
}
.grid-item.grid-item:nth-child(1) {
  font-size: 1.6em;
}
.grid-item.grid-item:nth-child(2) {
  font-size: 1.4em;
}

.item-counter-view2:after, .item-counter-view:after {
  line-height: var(10px);
  width: var(10px);
  height: var(10px);
}

.fas.fa-stream {
  font-size: 0.5em;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: linear-gradient(120deg, #ffae00 42%, #fdcd3b 70%);
  color: #141414;
  border-radius: 4em;
  /* or 50% width & line-height */
  padding: 6px;
  margin-left: 10px;
  border: solid 1px black;
  cursor: pointer;
  border: none;
}

.fa.fa-align-left:hover {
  background: white;
}

.video-descriptions {
  transition: all 0.5s;
}

.video-descriptions-show {
  opacity: 1;
  height: 1;
}

.video-descriptions-hide {
  opacity: 0;
  height: 0;
}

.element-suggestion-info {
  animation: suggestionInfoShow 0.5s ease-out;
}

@keyframes suggestionInfoShow {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.element-suggestion-info-span {
  display: block;
  font-size: 4em;
  color: white;
}

.category-fade-in {
  opacity: 1;
  transition: all 1s;
}

.category-fade-out {
  opacity: 0;
  transition: all 0.5s;
}

.category-description {
  top: 28%;
  position: absolute;
  pointer-events: none;
  width: 100%;
}

.category-description-text {
  display: block;
  color: white;
  font-size: 0.9em;
  line-height: 1.7em;
}

.cvoglogo {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.text-size3 {
  position: relative;
  font-size: 1em;
  color: white;
  display: inline-block;
}

.novologo {
  display: inline-block;
  height: 4em;
  position: relative;
  text-align: top;
  margin-left: 1em;
  filter: sepia(100%) hue-rotate(190deg);
}

