@import '../src/index.scss';

.competences-container {
    height: 20vh;
   // background: linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(224,236,46,1) 100%);
//background: linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(240,253,45,1) 100%);
    //background: linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(88,45,253,1) 100%);
    // background: #204131;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #197a4d, #204131);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #197a4d, #204131); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    

}

.competences {
    position: relative;
    height: 100%;
    width: 100%;
}

.competences span {
    color: honeydew;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
}

.competences .logo {
    width: 15%;
    object-fit: contain;
    justify-items: center;
    @extend .center-by-transform;
}