@import '../src/index.scss';

.player-wrapper {
    @extend .aspect-ratio-parent;
    z-index: 2;
    // transition: all 10s;
  }
  
  // .player-wrapper-background-selected {
  //   position: absolute;
  //   @extend .aspect-ratio-parent;
  //   background: red;
  //   z-index: 1;
  //   transform: scale(1.1);
  //   overflow: show;
  // }
  