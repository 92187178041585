@import '../src/index.scss';

$search-height: 35%;

.search-container {
    width: 100%;
    height: $search-container-height;
    padding-top: 2%;
    display: flex;
    // background: blue;
    // position: absolute;
    // display: block;
}

.search-container-show {
    opacity: 1;
    transition: all 0.5s;
}

.search-container-hide {
    opacity: 0;
    transition: all 0.5s;
}

.search {
    position: relative;
    @extend .vertical-center-by-transform;
    margin-left: calc(#{$fullwidth-offset}/2);
    // position: absolute;
    width: 32%;
    height: $search-height;
    // height: 100%;
    // @extend .center-by-transform;
    z-index: 2;

}

.search-field {
    // @extend .center-by-transform;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
}

.form-control {
    width: 100%;
}

.input-control {
    height: 100%;
    outline: none;
    font-size: 1.8em;
    border: none;
    background: $color-gradient6;; //$color-gradient1;
    transition: width 1s;
    color: $color-text;
    z-index: 1;
    // position: absolute;
    // border-top-right-radius: 10%;
    // border-bottom-right-radius: 10%;
}

.input-control-suggestion {
    // height: 100%;
    // outline: none;
    // font-size: 1.8em;
    // border: none;
    // background: $color-gradient6; //$color-gradient1;
    // transition: width 1s;
    // color: red;
    // z-index: 1;
    // position: absolute;
}

// .input-control:after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 100%;
//     width: 10%;
//     background: #fff;
//     border-top-left-radius: 50%;
//     border-bottom-left-radius: 50%;
//     user-select: none;
//     z-index: 100;
//   }


.input-control-active {
    width: calc(100% - 2em); 
    padding: 0 2em !important;
}

.input-control-deactivated {
    width: 0%; 
    padding: 0;
}
  
.fas.fa-search  {
    @extend .vertical-center-by-transform;
    position: absolute;
    padding-left: 0.2em;
    color: white;
    cursor: pointer; 
    transition: all 0.2s;
}

input[type="text"] {
    // padding: 0 2em !important;
  }

.fa-search-active, .fas.fa-search:hover {
    font-size: 2.1em;
}

.fa-search-deactivated {
    font-size: 2em;
}

.fas.fa-search + input {
// padding-left: 2em;
}


.search-suggestions-container {
    position: relative;
    @extend .vertical-center-by-transform;
    z-index: 1;
    width: calc(100% - 27.2%);
    height: $search-height;
    margin-right: calc(#{$fullwidth-offset}/2);
    position: relative;
    overflow-x: auto;
    @extend .disable-scrollbars;
}

.search-suggestion-container {
    display: flex;
    position: relative;
    padding-left: 5em;
    padding-right: 120vw;
    height: 100%;
    overflow: hidden;
    transform: translateX(-11em);
}

.search-suggestion {
    margin: auto;
    position: relative;
    height: 100%;
    background: $color-gradient4;
    margin-right: 2em;
    // margin: 10px;
    // padding: 10px;
    border-radius: 10px;
    transform-origin: center;
    transition: all 0.2s;
    cursor: pointer;
}

.search-suggestion:hover {
    transition: all 0.5s;
    transform: scale(1.05);
}

.search-suggestion--highligted {
    transition: 0.2s;
    opacity: 0;
}

.search-suggestion--default {
    transition: 0.2s;
    opacity: 1;
}

.search-suggestion--dummy {
    opacity: 0 !important;
}

.search-suggestion-span {
    // vertical-align: middle;

    color: $color-text;
    display: grid;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.5em;
    height: 100%;
    white-space: nowrap;
    // @extend .vertical-center-by-transform;
}

.search-suggestion-span--highligted {
    color: white;
}

.search-suggestion-span--default {
    color: $color-text;
}

.recommendations-container {
    position: absolute;
    height: $search-container-height;
    width: 100%; //calc(100% - #{$fullwidth-offset});
    z-index: 1000;
    pointer-events: none;
    overflow: hidden;
}

.recommendations-wrapper {
    position: relative;
    height: $search-container-height;
    width: 100%;
    pointer-events: none;
    overflow: hidden;

}

.recommendations {
    // background: $color2o1;
    pointer-events: all;
     //calc(100% - #{$fullwidth-offset});
    position: absolute;
    margin-left: calc(#{$fullwidth-offset}/2);
    // margin-right: calc(#{$fullwidth-offset}/2); //superflertius
    // padding-left: 2em;
    right: 0;
    // width: calc(100% - #{$fullwidth-offset});
    display: flex;
    overflow-x: auto;
    flex: 1;
    display: flex;
    height: 100%;
    width: 65%;
}

.recommendations-show {
    opacity: 1;
    transition: all 0.5s;
}

.recommendations-hide {
    opacity: 0;
    transition: all 0.3s;
}

.recommendations-slideout {
    //transition: 2s;
    transform: translateX(110%);
}

.recommendations-slidein {
    @extend .recommendations-slidein-animation;
}

.recommendations-slidein-animation {
    animation-name: recommendations-slidein-animation ;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 2.5s;
  }
    
    @keyframes recommendations-slidein-animation  {
      0% {
        // width: 0%;
        transform: translateX(110%);
      }
      30% {
        transform: translateX(110%);
        // width: 0%;
      }
      100% {
        transform: translateX(0%);
        // width: 60%;
      }
    }

// .recommendations:after {
//     content: "";
//     height: 100%;
//     width: 2em;
//     background: blue;
//     position: absolute;
//     box-shadow: 0 0 0 800px red;
// }

.recommendations-fade-left-container {
    // pointer-events: all;
    width: 65%; //calc(100% - #{$fullwidth-offset});
    position: absolute;
    // margin-left: calc(#{$fullwidth-offset}/2);
    // margin-right: calc(#{$fullwidth-offset}/2); //superflertius
    // padding-left: 2em;
    right: 0;
    z-index: 100000000;
    height: 100%;
    // width: calc(100% - #{$fullwidth-offset});
}

.recommendations-fade-left{
    pointer-events: none;
    width: 2em;
    background: $main-color-to-transparent-from-left;
    height: 100%;
}

.recommendations::-webkit-scrollbar {
    display: none;
  }

.recommendation {
    position: relative;
    min-height: $search-container-height;
    min-width: $search-container-height;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.recommendation-img, .recommendation-img-focus-outer {
    border-radius: 50%;
    margin: auto;

}

.recommendation-img {
    height: calc(#{$search-container-height}*0.8);
    width: calc(#{$search-container-height}*0.8);
    @extend .center-by-transform;
    position: absolute;
    z-index: 2;
    margin: auto;
    // margin-left: 1.5vh;
}

.recommendation-img:hover + .recommendation-img-focus-outer > .recommendation-img-focus-middle > .recommendation-img-focus-inner {
    height: calc(#{$search-container-height}*0.9);
    width: calc(#{$search-container-height}*0.9);
    transition: all 0.2s;
}

.recommendation-img-focus-outer {
    position: absolute;
    height: calc(#{$search-container-height});
    width: calc(#{$search-container-height});
    z-index: 1;
   
}
  
.recommendation-img-focus-middle {
    position: relative;
    height: calc(#{$search-container-height});
    width: calc(#{$search-container-height});

    overflow-y: hidden;
    margin: auto;
}

.recommendation-img-focus-inner {
    position: absolute;
    border-radius: 50%;
    background: $color-gradient3;
    @extend .center-by-transform;
    z-index: 1000;
    height: calc(#{$search-container-height}*0.8);
    width: calc(#{$search-container-height}*0.8);

}

.recommendation-name {
    position: relative;
}

.recommendation-name-span {
    color: white;
    top: 1em;
    position: absolute;
    @extend .horizontal-center-by-transform;
    z-index: 1000;
}



// .recommendation-img-focus-inner--focus {
//     height: calc(#{$search-container-height});
//     width: calc(#{$search-container-height});
// }

// .recommendation-img-focus-inner--focus-off {
//     height: calc(#{$search-container-height}*0.8);
//     width: calc(#{$search-container-height}*0.8);
// }






